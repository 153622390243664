const baseStyle = {
  _focus: {
    boxShadow: 'none'
  },
  _focusVisible: {
    boxShadow: 'outline'
  }
};

export default {
  baseStyle
};
