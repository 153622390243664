import { extendTheme } from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'focus-visible/dist/focus-visible';
import { theme as proTheme } from '@chakra-ui/pro-theme';
import * as components from '../components/chakraUIPro/proTheme/components';
import * as foundations from '../components/chakraUIPro/proTheme/foundations';

const theme: Record<string, any> = extendTheme(proTheme, {
  colors: {
    styles: {
      global: {
        'html, body': { color: 'gray.700' },
        a: { _hover: 'brand.500', letterSpacing: '1px' },
        h1: { color: 'gray.700', letterSpacing: '1px' },
        h2: { color: 'gray.700', letterSpacing: '2px' },
        h3: { color: 'gray.700', letterSpacing: '2px' },
        h4: { color: 'gray.700', letterSpacing: '1px' },
        h5: { color: 'gray.700', letterSpacing: '1px' },
        h6: { color: 'gray.700', letterSpacing: '1px' }
      }
    },
    fontWeights: {
      normal: '400',
      medium: '400',
      bold: '500'
    },
    brand: {
      exact500: '#36627F',
      exact400: '#6BA8B0',
      exact300: '#87C2C9',
      50: '#e0f5fc',
      100: '#c6e0e6',
      200: '#a8ccd4',
      300: '#89b9c2',
      400: '#69a7af',
      500: '#508996',
      600: '#3c6775',
      700: '#274754',
      800: '#122734',
      900: '#000c16',
      alt700: '#203235'
    },
    black: '#1d273b',
    black300: '#475c6e',
    offWhite: '#ecf0f0',
    offWhite500: '#dcddd9',
    offWhite600: '#aba9ac',
    secondary: {
      50: '#FDEFE9',
      100: '#FBDED3',
      200: '#F8BEA7',
      300: '#F49D7B',
      400: '#F17D4F',
      500: '#ED5C23',
      600: '#BE4A1C',
      700: '#8E3715',
      800: '#5F250E',
      900: '#2F1207'
    }
  },
  ...foundations,
  components: {
    ...components,
    Table: {
      baseStyle: {
        table: {
          bg: 'inherit'
        },
        thead: {
          th: {
            color: 'inherit',
            bg: 'inherit',
            fontWeight: 'semibold',
            borderTop: 'none',
            borderColor: 'subtle-border'
          }
        },
        tbody: {
          td: {
            borderColor: 'subtle-border'
          }
        }
      },
      variants: {
        simple: () => ({
          th: {
            color: 'black',
            bg: 'inherit'
          }
        })
      }
    },
    Popover: {
      baseStyle: {
        arrow: {
          bgColor: 'red'
        },
        content: {
          bgColor: 'bg-brand',
          borderColor: 'bg-brand',
          borderWidth: '0',
          _focus: { outline: 'none', borderColor: 'brand.100' },
          _focusVisible: { boxShadow: 'md', borderColor: 'brand.100' }
        }
      }
    },
    Progress: {
      variants: {
        solid: {
          track: {
            bg: 'bg-darker'
          },
          filledTrack: {
            bg: 'secondary.500'
          }
        }
      }
    }
  },
  space: {
    '4.5': '1.125rem'
  },
  semanticTokens: {
    colors: {
      'bg-default': {
        default: 'gray.200',
        _dark: 'gray.800'
      },
      'bg-alt': {
        default: 'gray.50',
        _dark: 'black'
      },
      'bg-darker': {
        default: 'gray.400',
        _dark: 'gray.700'
      },
      'bg-step': {
        default: 'gray.300',
        _dark: 'gray.800'
      },
      'bg-brand': {
        default: 'brand.400',
        _dark: 'brand.700'
      },
      'bg-brand-dark': {
        default: 'brand.200',
        _dark: 'brand.800'
      },
      'bg-default-inverted': {
        default: 'gray.700',
        _dark: 'gray.200'
      },
      'bg-navbar': {
        default: 'gray.100',
        _dark: 'gray.600'
      },
      'bg-surface': {
        default: 'whiteAlpha.500',
        _dark: 'blackAlpha.400'
      },
      'bg-surface-landing': {
        default: 'brand.200',
        _dark: 'brand.alt700'
      },
      accent: {
        default: 'brand.600',
        _dark: 'brand.500'
      },
      'subtle-border': {
        default: 'blackAlpha.100',
        _dark: 'whiteAlpha.100'
      },
      'step-active': {
        default: 'brand.600',
        _dark: 'brand.300'
      },
      'step-inactive': {
        default: 'brand.500',
        _dark: 'brand.600'
      }
    }
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false
  }
});

export default theme;
