import { gql } from '@apollo/client';

export const GetUser = gql`
  query getUser {
    user {
      id
      lName
      created
      email
      fName
      modified
      phone
      status
      organisations {
        created
        id
        modified
        name
        status
        tier
        access {
          scopes
          groups
        }
        billing {
          email
          address {
            code
            country
            line1
            line2
            state
          }
          lastChargedDate
          name
          taxNumber
        }
      }
    }
  }
`;
export const DESTINATION = gql`
  query destination($orgId: String!, $id: String!) {
    destination(orgId: $orgId, dstId: $id) {
      destination {
        http {
          authorisation {
            headers {
              key
              secret
              value
            }
          }
          threads
          timeout
          url
        }
      }
      id
      status
      organisationId
      notifications {
        email {
          to
        }
        status
      }
      name
      mappingTemplate {
        request
        example
      }
    }
  }
`;
export const DESTINATION_LIST = gql`
  query destinationList($orgId: String!) {
    destinationList(orgId: $orgId) {
      id
      created
      modified
      status
      name
      lastJob {
        id
        created
        modified
        status
        organisationId
        destinationId
        filename
        size
        meta {
          started
          lastIndex
          lastIteration
          nextOffset
          errors {
            total
          }
          skipped {
            total
          }
        }
      }
    }
  }
`;
export const JOB_GET = gql`
  query job($id: String!) {
    job(id: $id) {
      created
      destinationId
      filename
      id
      status
      size
      organisationId
      modified
      meta {
        lastIndex
        processed
        started
        nextOffset
        errors {
          total
        }
        skipped {
          total
        }
      }
    }
  }
`;

export const JOB_LIST = gql`
  query joblist($dstId: String!, $limit: Int, $nextToken: String) {
    jobList(dstId: $dstId, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        created
        destinationId
        filename
        id
        status
        size
        organisationId
        modified
        meta {
          lastIndex
          lastIteration
          processed
          started
          nextOffset
          errors {
            total
          }
          skipped {
            total
          }
        }
      }
    }
  }
`;
export const JOB_FILE_DONWLOAD = gql`
  query jobFileDownloadLink($orgId: String!, $dstId: String!, $jobId: String!, $type: String!) {
    jobFileDownloadLink(orgId: $orgId, dstId: $dstId, jobId: $jobId, type: $type) {
      url
    }
  }
`;
export const ORG_STATS = gql`
  query orgStats($orgId: String!, $month: Int!) {
    orgStats(orgId: $orgId, month: $month) {
      availableHours
      currentUsage
      destinationStats {
        dates {
          day
          time
          units
          lines
        }
        id
        name
      }
    }
  }
`;
export const GET_USERSLIST = gql`
  query usersList($input: UserStatusListInput) {
    usersList(input: $input) {
      users {
        id
        created
        modified
        status
        fName
        lName
        email
        scopes
      }
      invites {
        id
        created
        modified
        status
        email
      }
      usersNextToken
      invitesNextToken
    }
  }
`;

export const SFTP_ACCOUNTS_LIST = gql`
  query listSftpAccounts($input: SftpAccountsListInput) {
    listSftpAccounts(input: $input) {
      accounts {
        created
        id
        modified
        organisationId
        passwordMasked
        status
        username
      }
      nextToken
      total
    }
  }
`;

export const GET_CHART = gql`
  query getChart($orgId: String!, $type: String!, $interval: String!, $dstId: String) {
    getChart(orgId: $orgId, type: $type, interval: $interval, dstId: $dstId) {
      results
    }
  }
`;
