const variants = {
  menu: () => ({
    borderRadius: 'lg',
    _hover: {
      textDecoration: 'none',
      bg: 'bg-subtle'
    }
  })
};

export default {
  variants
};
