import Router from 'next/router';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Pirsch, PirschWebClient } from 'pirsch-sdk/web';
import { PirschBrowserHit, Scalar } from 'pirsch-sdk';

export type PirschEvent = {
  name: string;
  duration?: number;
  meta?: Record<string, Scalar>;
  hit?: Partial<PirschBrowserHit>;
};

export type PirschAnalyticsContextType = {
  logEvent(params: PirschEvent): Promise<void>;
};

type AnalyticsContextProps = {
  children: React.ReactNode;
};

export const PirschAnalyticsContext = createContext({} as PirschAnalyticsContextType);

export function PirschAnalyticsProvider({ children }: AnalyticsContextProps): any {
  // we create a default state to keep track of whether Pirsch
  // has been initialized, if we're tracking a unique user,
  // and to hold all of our trackers
  const [client, setClient] = useState<PirschWebClient>();

  // We create a function handle all route changes that occur
  // and track a users movements across pages in our app
  const handleRouteChange = async (url) => {
    if (client) {
      await client.hit({ url });
    }

    if (process.env.NEXT_PUBLIC_STAGE !== 'prod') {
      // eslint-disable-next-line no-console
      console.info('Pirsch event', { url });
    }
  };

  // We'll define our logEvent function before useEffect
  const logEvent = async ({ name, meta, duration, hit }: PirschEvent) => {
    if (client) {
      await client.event(name, duration, meta, hit);
    }

    if (process.env.NEXT_PUBLIC_STAGE !== 'prod') {
      // eslint-disable-next-line no-console
      console.info('Pirsch event', { name, duration, meta, hit });
    }
  };

  // We only want to initialize GA on the client side
  // This will fail if you're trying to initialize server side
  // useEffect will help us handle this case as it only runs
  // client side
  useEffect(() => {
    // Initialize tracking code for non-local only
    if (!client) {
      // Create a client with the identification code you have configured on the Pirsch dashboard.
      const newClient = new Pirsch({
        identificationCode: process.env.NEXT_PUBLIC_PIRSCH_CODE || ''
      });

      setClient(newClient);

      if (process.env.NEXT_PUBLIC_STAGE === 'prod') {
        newClient.hit();
      }

      // Handle all route changes
      Router.events.on('routeChangeComplete', handleRouteChange);
    }

    // clean up
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const dispatchContextValue = useMemo(() => ({ logEvent }), [logEvent]);

  return <PirschAnalyticsContext.Provider value={dispatchContextValue}>{children}</PirschAnalyticsContext.Provider>;
}

export const usePirschAnalytics = () => {
  const c = useContext(PirschAnalyticsContext);
  if (!c) throw new Error('Cannot use usePirschAnalytics when not under the PirschAnalyticsProvider');
  return c;
};
