import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Auth } from '@aws-amplify/auth';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Hub } from '@aws-amplify/core';
import { Reducer, useImmerReducer } from 'use-immer';
import * as Sentry from '@sentry/nextjs';
import { useLazyQuery } from '@apollo/client';
import { Organisation, UserAccess } from '@simplyanvil/smartparse-types';
import { useRouter } from 'next/router';
import { GetUser } from '../graphql/queries';

export enum AuthStatus {
  /* eslint-disable no-unused-vars */
  LOADING,
  ANONYMOUS,
  AUTHENTICATED,
  PENDING_SIGN_IN,
  PENDING_SIGN_UP,
  PENDING_SIGN_OUT
}

export type UserOrganisation = Organisation & { access: UserAccess };

export type AuthUserType = {
  id: string;
  email: string;
  phone?: string;
  fName?: string;
  lName?: string;
  organisations?: UserOrganisation[];
  status?: string;
};

export type AuthStateContextType = {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: AuthUserType | undefined | null;
  idToken: string | undefined;
  sessionStatus: AuthStatus;
  organisations: Organisation[];
  currentOrganisation: Organisation;
  showCreate: boolean;
  // selectedDestinationId: string;
  // selectedJobId: string;
};

export type AuthDispatchContextType = {
  dispatch: (action: any) => void;
  setOrganisationById: (id: string) => void;
  refreshUser: () => Promise<void>;
  refreshCognitoUser: () => Promise<void>;
  signOut: () => Promise<void>;
  updateUser: () => Promise<void>;
};

type AuthContextProps = {
  children: React.ReactNode;
};

const sanitizeUser = (data: any): AuthUserType | null => {
  if (!data) {
    return null;
  }

  // cognito attributes
  return {
    id: data.attributes && (data.attributes.sub as string),
    email: data.attributes && data.attributes.email,
    fName: data.attributes && data.attributes.name,
    lName: data.attributes && data.attributes.family_name,
    // No phone number available in cognito attributes
    phone: data.attributes && data.attributes.phone_number,
    organisations: []
  };
};
export const AuthStateContext = createContext({} as AuthStateContextType);
export const AuthDispatchContext = createContext({} as AuthDispatchContextType);

const authReducer: Reducer<AuthStateContextType> = (draft, action) => {
  switch (action.type) {
    case 'clearSession': {
      draft.user = undefined;
      draft.idToken = undefined;
      draft.isAuthenticated = false;
      draft.isLoading = true;
      draft.sessionStatus = AuthStatus.ANONYMOUS;
      break;
    }
    case 'anonymousSession': {
      draft.user = undefined;
      draft.idToken = undefined;
      draft.isAuthenticated = false;
      draft.isLoading = false;
      draft.sessionStatus = AuthStatus.ANONYMOUS;
      break;
    }
    case 'signingIn': {
      draft.sessionStatus = AuthStatus.PENDING_SIGN_IN;
      break;
    }
    case 'signedIn': {
      draft.user = sanitizeUser(action.user);
      draft.idToken = action.user?.signInUserSession?.idToken?.jwtToken;
      draft.isAuthenticated = true;
      draft.isLoading = false;
      draft.sessionStatus = AuthStatus.AUTHENTICATED;
      break;
    }
    case 'signingUp': {
      draft.sessionStatus = AuthStatus.PENDING_SIGN_UP;
      break;
    }
    case 'signingOut': {
      draft.sessionStatus = AuthStatus.PENDING_SIGN_OUT;
      break;
    }
    case 'signedOut': {
      draft.user = undefined;
      draft.idToken = undefined;
      draft.isAuthenticated = false;
      draft.isLoading = false;
      draft.sessionStatus = AuthStatus.ANONYMOUS;
      break;
    }

    case 'setUserDetail': {
      draft.user.organisations = action.organisations || [];
      draft.showCreate = action.organisations.length === 0;
      if (action.organisations) {
        draft.organisations = action.organisations;
        draft.currentOrganisation =
          action.organisations.find((o) => o.id === action.selectedOrg) || action.organisations[0];
      }
      // Set user phone
      if (action.phone) {
        draft.user.phone = action?.phone;
      }
      // Set user Status
      if (action.status) {
        draft.user.status = action?.status;
      }
      draft.isLoading = false;

      break;
    }
    case 'setOrganisation': {
      draft.currentOrganisation = action.organisation;
      draft.isLoading = false;
      break;
    }
    case 'setOrganisationById': {
      const currentOrg = draft.organisations.find((o) => o.id === action.orgId);
      draft.currentOrganisation = currentOrg;
      draft.isLoading = false;
      // draft.currentOrganisation = draft.organisations.find((o) => o.id === action.orgId) || action.organisations[0];
      break;
    }
    case 'updateUserdetails': {
      draft.user = action.dynamoUser.data.user;
      draft.idToken = action.user?.signInUserSession?.idToken?.jwtToken;
      draft.isAuthenticated = true;
      draft.isLoading = false;
      draft.sessionStatus = AuthStatus.AUTHENTICATED;
      break;
    }
    case 'updateUserIdToken': {
      draft.idToken = action.idToken;
      break;
    }
    default: {
      throw new Error(`Unhandled dispatch: ${action.type}`);
    }
  }
};

const initialState = {
  user: undefined,
  idToken: undefined,
  isAuthenticated: false,
  isLoading: true,
  sessionStatus: AuthStatus.LOADING,
  organisations: [],
  currentOrganisation: undefined,
  selectedDestinationId: undefined,
  selectedJobId: undefined,
  showCreate: false
};

export function AuthProvider({ children }: AuthContextProps): any {
  const [state, dispatch] = useImmerReducer<any, any>(authReducer, initialState);
  const { user, isAuthenticated, idToken } = state;
  const router = useRouter();
  const [getUser, { loading: getUserLoading, data: getUserData, called: getUserCalled }] = useLazyQuery(GetUser, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  // console.log('authContext:state', state);

  const signOut = useCallback(async (): Promise<void> => {
    dispatch({ type: 'signingOut' });
    try {
      await Auth.signOut();
      // Remove local profiles
      localStorage.removeItem('profiles');

      // Clear Sentry user data
      Sentry.configureScope((scope) => scope.setUser(null));

      dispatch({ type: 'signedOut' });
    } catch (err) {
      console.error('AuthProvider:signOut:error', err);
    }
  }, [dispatch, getUser]);

  const setOrganisationById = useCallback(
    (id: string): void => {
      dispatch({ type: 'setOrganisationById', orgId: id });
      router.push(
        router.asPath,
        {
          pathname: `/dashboard/${id}`
        },
        { shallow: true }
      );
    },
    [dispatch]
  );

  const refreshUser = useCallback(async () => {
    await getUser();
  }, [getUser]);

  const refreshCognitoUser = async () => {
    const cognitoSession = await Auth.currentSession();
    dispatch({ type: 'updateUserIdToken', idToken: cognitoSession.getIdToken().getJwtToken() });
  };

  // Check auth status
  const checkAuthStatus = useCallback(async () => {
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        getUser();
        dispatch({ type: 'signedIn', user: currentUser });
      })
      .catch(() => dispatch({ type: 'anonymousSession' }));
  }, [dispatch]);

  // will update user,
  const updateUser = useCallback(async () => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then((currentUser) => {
      getUser().then((res) => {
        dispatch({ type: 'updateUserdetails', user: currentUser, dynamoUser: res });
      });
    });
  }, [dispatch]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  // Amplify Hub handling
  useEffect(() => {
    const hubHandler = async ({ payload }) => {
      if (payload.event === 'signIn') {
        // Add missing attributes for forced password resets.
        const tempUser = payload.data || (await Auth.currentUserInfo());

        // Fetch User details
        getUser();

        dispatch({ type: 'signedIn', user: tempUser });
      }

      if (payload.event === 'signOut') {
        dispatch({ type: 'clearSession' });
      }
    };

    Hub.listen('auth', hubHandler);

    return () => {
      Hub.remove('auth', hubHandler);
    };
  }, [dispatch, getUser]);

  // update the displayed url
  // TODO: theres a lot of holes with this logic. What about posts?
  const updateUrl = (orgId) => {
    const path = router.asPath;
    if (path.indexOf('user') > -1) {
      router.push({ pathname: `/users/${orgId}` });
      return;
    }
    router.push({ pathname: `/dashboard/${orgId}` });
  };

  useEffect(() => {
    // there are 2 routes that need to be supported, that need to be consolidated
    // [[location]] for any dashboard pages
    // [orgId] for other pages
    const orgUriParam = (router.query.location && router.query.location[0]) || router.query.orgId;

    const path = router.asPath;
    const isDashboard = path.indexOf('dashboard') >= 0;
    // Only update url if theres no orgId already
    if (isDashboard && !orgUriParam) {
      const orgId = orgUriParam || state?.currentOrganisation?.id;
      if (orgId) {
        updateUrl(orgId);
      }
    }

    if (state.currentOrganisation && orgUriParam !== state.currentOrganisation.id) {
      dispatch({ type: 'setOrganisationById', orgId: orgUriParam || state.currentOrganisation.id });
    }

    if (orgUriParam === 'newOrg') {
      dispatch({ type: 'setOrganisation', organisation: 'newOrg' });
    }
  }, [router.query]);

  useEffect(() => {
    if (!getUserCalled && isAuthenticated) {
      getUser();
      return;
    }

    if (getUserLoading) {
      return;
    }

    if (getUserData?.user && isAuthenticated) {
      // Set sentry data for error and performance reporting.
      Sentry.setUser({ email: user?.email, id: user?.id });

      // No org? Send to org onboarding
      if (!getUserData.user.organisations?.[0]) {
        router.push('/dashboard/newOrg');
        return;
      }

      // Org id either from the urlBar or the first organisation in the list
      // TODO: check url id matches a organisation in the list
      const orgId = (router.query.location && router.query.location[0]) || getUserData.user.organisations[0].id;

      // Check if dashboard. Only then update urr with orgId
      const path = router.asPath;

      // Redirect to dashboard on successful sign in
      const isSignin = path.indexOf('sign-in') >= 0;
      if (isSignin) {
        router.push(
          router.asPath,
          {
            pathname: `/dashboard`
          },
          {}
        );
      }

      // TODO: too many holes with this and it's not a single point for testing.
      const isDashboard = path.indexOf('dashboard') >= 0;
      const isUser = path.indexOf('user') >= 0;
      if (isDashboard || isUser) {
        updateUrl(orgId);
      }

      dispatch({
        type: 'setUserDetail',
        ...getUserData.user,
        selectedOrg: orgId
      });
    }
  }, [getUserLoading, getUserData, dispatch, isAuthenticated, getUserLoading, user?.email, user?.id]);

  const dispatchContextValue = useMemo(
    () => ({
      setOrganisationById,
      refreshUser,
      refreshCognitoUser,
      signOut,
      dispatch,
      updateUser,
      idToken
    }),
    [refreshUser, signOut, dispatch, updateUser, idToken]
  );

  return (
    <AuthDispatchContext.Provider value={dispatchContextValue}>
      <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
}

export const useAuthState = () => {
  const c = useContext(AuthStateContext);
  if (!c) throw new Error('Cannot use useAuthState when not under the AuthProvider');
  return c;
};

export const useAuthDispatch = () => {
  const c = useContext(AuthDispatchContext);
  if (!c) throw new Error('Cannot use useAuthDispatch when not under the AuthProvider');
  return c;
};
