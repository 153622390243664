/* eslint-disable @typescript-eslint/ban-ts-comment */
import Router from 'next/router';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
// eslint-disable-next-line import/no-unresolved
import { UaEventOptions } from 'react-ga4/types/ga4';
import { AuthStateContext } from './AuthContext';
import { PirschAnalyticsContext, PirschAnalyticsProvider } from './PirschAnalyticsContext';

export type AnalyticsContextType = {
  // eslint-disable-next-line no-unused-vars
  logEvent(params: UaEventOptions): void;
};

type AnalyticsContextProps = {
  children: React.ReactNode;
};

export const AnalyticsContext = createContext({} as AnalyticsContextType);

export function AnalyticsProvider({ children }: AnalyticsContextProps): any {
  const { user } = useContext(AuthStateContext);
  const { logEvent: LogPirschEvent } = useContext(PirschAnalyticsContext);

  // we create a default state to keep track of whether GA
  // has been initialized, if we're tracking a unique user,
  // and to hold all of our trackers
  const [analytics, setAnalytics] = useState({
    isInitialized: false,
    hasUser: false
  });

  // We create a function handle all route changes that occur
  // and track a users movements across pages in our app
  const handleRouteChange = (url) => {
    ReactGA.send({ hitType: 'pageview', page: url });
  };

  // We'll define our logEvent function before useEffect
  const logEvent = (params: UaEventOptions) => {
    if (analytics.isInitialized) {
      ReactGA.event({ ...params });

      const pirschParams: any = {
        name: !params.action ? params.category : `${params.category}: ${params.action}`
      };

      if (params.label) {
        pirschParams.meta = { label: params.label };
      }

      if (params.value) {
        pirschParams.meta = { ...(pirschParams.meta || {}), value: params.value };
      }

      try {
        LogPirschEvent(pirschParams)
          .then()
          .catch((err) => console.error(err));
      } catch (e) {
        console.error(e);
      }
    }
  };

  // We only want to initialize GA on the client side
  // This will fail if you're trying to initialize server side
  // useEffect will help us handle this case as it only runs
  // client side
  useEffect(() => {
    const { isInitialized, hasUser } = analytics;

    // initialize GA with our tracking id
    if (!isInitialized) {
      ReactGA.initialize([
        {
          trackingId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTIC || '',
          gaOptions: user?.id ? { userId: user?.id } : {}
        }
      ]);

      // Handle all route changes
      Router.events.on('routeChangeComplete', handleRouteChange);

      setAnalytics((prev) => ({
        ...prev,
        isInitialized: true,
        hasUser: Boolean(user?.id)
      }));

      // in case we don't have the user initially,
      // we handle setting a user in our tracker
    } else if (isInitialized && !hasUser && user?.id) {
      ReactGA.set({ userId: user?.id });

      setAnalytics((prev) => ({
        ...prev,
        hasUser: Boolean(user?.id)
      }));
    }

    // clean up
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [analytics, user?.id]);

  const dispatchContextValue = useMemo(() => ({ logEvent }), [logEvent]);

  return (
    <PirschAnalyticsProvider>
      <AnalyticsContext.Provider value={dispatchContextValue}>{children}</AnalyticsContext.Provider>
    </PirschAnalyticsProvider>
  );
}

export const useAnalytics = () => {
  const c = useContext(AnalyticsContext);
  if (!c) throw new Error('Cannot use useAnalytics when not under the AnalyticsProvider');
  return c;
};
