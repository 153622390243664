import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { DefaultSeo } from 'next-seo';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import Script from 'next/script';
import theme from '../libs/theme';
import '../styles/main.scss';
import { AuthProvider } from '../contexts/AuthContext';
import { AnalyticsProvider } from '../contexts/AnalyticsContext';
import { PirschAnalyticsProvider } from '../contexts/PirschAnalyticsContext';
import '../components/Post/prism-okaidia.css'; // Blog coding block style
import 'easymde/dist/easymde.min.css';

interface AppProps {
  Component: any;
  pageProps: any;
}

/* AWS Amplify and Appsync */
const APPSYNC_URL = process.env.NEXT_PUBLIC_APP_SYNC_API_URL;
const APPSYNC_KEY = process.env.NEXT_PUBLIC_APP_SYNC_API_KEY as string;
const httpLink = createHttpLink({
  uri: APPSYNC_URL
});
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authHeaders: { [header: string]: string } = {};
  await Auth.currentSession()
    .then((session) => {
      authHeaders.authorization = session.getIdToken().getJwtToken();
    })
    .catch(() => {
      // console.error('Auth session token error', err);
      authHeaders['x-api-key'] = APPSYNC_KEY;
    });

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...authHeaders
    }
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

// Copied and variable-ised settings from src/aws-exports.js. Check for changes when changing amplify
const config = {
  aws_project_region: process.env.NEXT_PUBLIC_REGION,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.NEXT_PUBLIC_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.NEXT_PUBLIC_REGION
};

Amplify.configure(config);

const App: React.FC<AppProps> = ({ Component, pageProps }) => (
  <ChakraProvider resetCSS theme={theme}>
    <DefaultSeo
      // dangerouslyDisableGooglebot={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
      dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
      dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
      openGraph={{
        type: 'website',
        locale: 'en_GB',
        url: 'https://smartparse.io/',
        site_name: 'SmartParse',
        images: [
          {
            url: 'https://smartparse.io/images/card.webp',
            width: 1068,
            height: 534,
            alt: 'Smart Parse'
          }
        ]
      }}
      twitter={{
        handle: '@smartparse',
        site: '@smartparse',
        cardType: 'summary_large_image'
      }}
      additionalLinkTags={[
        {
          rel: 'manifest',
          href: '/site.webmanifest.json'
        },
        {
          rel: 'shortcut icon',
          href: '/android-chrome-192x192.png'
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png'
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/android-chrome-192x192.png'
        }
      ]}
      additionalMetaTags={[
        {
          name: 'mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'application-name',
          content: 'SmartParse'
        },
        {
          name: 'apple-mobile-web-app-title',
          content: 'SmartParse'
        },
        {
          name: 'theme-color',
          content: '#495163'
        },
        {
          name: 'msapplication-navbutton-color',
          content: '#495163'
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black-translucent'
        },
        {
          name: 'msapplication-starturl',
          content: '/dashboard/'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
        },
        {
          name: 'msapplication-TileColor',
          content: '#495163'
        }
      ]}
    />

    <ApolloProvider client={client as any}>
      <PirschAnalyticsProvider>
        <AnalyticsProvider>
          <AuthProvider>
            <Component {...pageProps} />
          </AuthProvider>
        </AnalyticsProvider>
      </PirschAnalyticsProvider>
    </ApolloProvider>

    {/* Google tag */}
    <Script
      strategy='lazyOnload'
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
    />
    <Script id='ga-analytics'>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
      `}
    </Script>
  </ChakraProvider>
);

export default App;
